body {
  margin: 0;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f7f7f7; */
  
    font-family: 'Muli', sans-serif;

}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.logo-img {
  cursor: pointer;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  display: none !important;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
  display: none !important;
}

body {
  top: 0px !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd { 
  display: none !important;
}